<template>
  <v-card tile class="modal">
    <!-- <v-card-title class="text-h5 primary mb-2">
      <span class="white--text">
        Notificación
      </span>
    </v-card-title> -->

    <v-card-text
      class="fill-height text-center d-flex flex-column align-center justify-center"
    >
      <v-container>
        <h2>¿Olvidó su contraseña?</h2>
        <span>
          <p class="mt-2 subheading">
            Comunícate con nuestros agentes de post-venta para que te apoyen.
          </p>
          <v-btn
            color="info"
            :href="`mailto:corporativo@ridery.app`"
            target="_blank"
            width="200"
          >
            <v-icon style="color: #ffffff !important">
              {{ icons.mdiEmailOutline }}
            </v-icon>
            <span class="ml-2"> Email </span>
          </v-btn>
          <v-btn
            color="success"
            :href="`https://api.whatsapp.com/send?phone=+584128835418&text=Olvidé%20mi%20contraseña,%20necesito%20ayuda%20por%20favor`"
            target="_blank"
            class="ml-md-2"
            :class="!$vuetify.breakpoint.mdAndUp && 'mt-2'"
            width="200"
          >
            <v-icon style="color: #ffffff !important">
              {{ icons.mdiWhatsapp }}
            </v-icon>
            <span class="ml-2"> Whatsapp </span>
          </v-btn>
        </span>
      </v-container>
    </v-card-text>
  </v-card>
</template>
<script>
import { mdiWhatsapp, mdiEmailOutline } from "@mdi/js";
import { mapActions, mapState } from "vuex";

export default {
  data() {
    return {
      icons: {
        mdiWhatsapp,
        mdiEmailOutline,
      },
    };
  },
  computed: {
    ...mapState("auth", ["admin_phone", "admin_email"]),
  },
};
</script>
<style lang="sass" scoped>
.vuedl-layout__closeBtn
  color: rgba(58, 53, 65, 0.54)
    // color: white
    // opacity: 1
    // margin-top: 8px

.v-dialog.vuedl-layout.v-dialog--active.v-dialog--fullscreen
  .v-card.v-sheet.theme--light.rounded-0
    height: 100vh

a
  text-decoration: none
</style>
