<template>
  <div class="auth-wrapper auth-v2 min-vh">
    <div class="auth-inner">
      <v-row class="auth-row ma-0">
        <v-col
          lg="8"
          class="d-none d-lg-block position-relative overflow-hidden pa-0"
        >
          <div class="side-panel fade-in">
            <!-- <img
              height="100%"
              width="100%"
              class="side-panel-image"
              :src="require('./assets/landignpage.jpg')"
            /> -->
            <v-img
              :src="
                !$vuetify.theme.isDark
                  ? require('./assets/white-background.jpg')
                  : require('./assets/dark-background.png')
              "
              class="min-vh"
            >
              <v-row
                class="d-flex column justify-center align-center"
                style="min-height: 100vh"
              >
                <v-col cols="6" class="d-flex flex-column">
                  <!-- <h1
                    class="font-weight-bold mb-4 text-h1 slide-in-left"
                    :class="
                      $vuetify.breakpoint.mdAndUp ? 'text-h2 ' : 'text-h2'
                    "
                    style="font-family: 'Urbanist' !important"
                    :style="
                      !$vuetify.theme.isDark && 'color: #FFFFFF !important;'
                    "
                  >
                    {{ $t("Corporate Panel") }}
                  </h1> -->
                  <img
                    class="slide-in-down"
                    :src="
                      !$vuetify.theme.isDark
                        ? require('./assets/dark-logo.svg')
                        : require('./assets/white-logo.svg')
                    "
                    alt="Logo"
                  />
                </v-col>
              </v-row>
            </v-img>
          </div>
        </v-col>

        <v-col
          lg="4"
          class="d-flex align-center auth-bg pa-10 pb-0"
          style="min-height: 100vh"
        >
          <v-row>
            <v-col cols="12" sm="8" md="6" lg="12" class="mx-auto">
              <v-card flat>
                <v-card-text
                  class="d-flex flex-column text-start mb-8"
                  v-if="$vuetify.breakpoint.mdAndDown"
                >
                  <!-- <span
                    class="font-weight-bold text-h1 text-start slide-in-left"
                    :class="
                      $vuetify.breakpoint.mdAndUp ? 'text-h5 ' : 'text-h5'
                    "
                    style="font-family: 'Urbanist' !important"
                    :style="
                      !$vuetify.theme.isDark && 'color: #000522 !important;'
                    "
                  >
                    {{ $t("Corporate Panel") }}
                  </span> -->
                  <!-- <span
                    class="text-h6 mb-2 slide-in-right"
                    style="font-family: 'Urbanist' !important"
                    :style="
                      !$vuetify.theme.isDark && 'color: #000522 !important;'
                    "
                  >
                    {{ $t("By") }}
                  </span> -->
                  <img
                    :src="
                      !$vuetify.theme.isDark
                        ? require('./assets/dark-logo.svg')
                        : require('./assets/white-logo.svg')
                    "
                    class="slide-in-right"
                    style="max-width: 100%"
                  />
                </v-card-text>
                <v-card-text>
                  <p class="text-2xl font-weight-semibold text--primary mb-2">
                    {{ $t("Welcome!") }}
                  </p>
                  <p class="mb-2">
                    {{
                      $t("Please sign-in to your account and manage your trips")
                    }}
                  </p>
                </v-card-text>

                <!-- login form -->
                <v-card-text>
                  <ValidationObserver v-slot="{ invalid }">
                    <v-form @submit.prevent="handleLogin()" spellcheck="false">
                      <ValidationProvider
                        :name="toLowerCase($t('Email'))"
                        rules="required|email"
                        v-slot="{ errors }"
                        :debounce="150"
                      >
                        <!-- :detectInput="false" -->
                        <v-text-field
                          v-model="userData.email"
                          :autofocus="$vuetify.breakpoint.mdAndUp"
                          outlined
                          :label="$t('Email')"
                          :placeholder="$t('Email')"
                          hide-details="auto"
                          class="mb-6"
                          autocapitalize="none"
                          :error-messages="errors[0]"
                        ></v-text-field>
                      </ValidationProvider>

                      <ValidationProvider
                        :name="toLowerCase($t('Password'))"
                        rules="required|min:6"
                        v-slot="{ errors }"
                        :debounce="150"
                      >
                        <v-text-field
                          v-model="userData.password"
                          outlined
                          :type="isPasswordVisible ? 'text' : 'password'"
                          :label="$t('Password')"
                          :placeholder="$t('Password')"
                          :append-icon="
                            isPasswordVisible
                              ? icons.mdiEyeOffOutline
                              : icons.mdiEyeOutline
                          "
                          hide-details="auto"
                          class="mb-2"
                          @click:append="isPasswordVisible = !isPasswordVisible"
                          :error-messages="errors[0]"
                        ></v-text-field>
                      </ValidationProvider>

                      <div
                        class="
                          d-flex
                          align-center
                          justify-space-between
                          flex-wrap
                        "
                        v-if="true"
                      >
                        <span>
                          <v-fade-transition mode="out-in" v-if="!false">
                            <v-avatar
                              color="grey-lighten-2"
                              size="36"
                              @click="handleLocale()"
                              class="mySwitcher"
                            >
                              {{
                                locales.find((l) => l.locale === $i18n.locale)
                                  .title
                              }}
                            </v-avatar>
                          </v-fade-transition>
                          <app-bar-theme-switcher
                            class="mx-4"
                          ></app-bar-theme-switcher>
                        </span>
                        <!-- forget link -->
                        <router-link
                          class="ms-3"
                          :to="{ name: 'ForgotPassword' }"
                        >
                          {{ $t("Forgot Password?") }}
                        </router-link>
                        <!-- <a class="ms-3" @click="toggleModal()">
                          {{ $t("Forgot Password?") }}
                        </a> -->
                      </div>

                      <!-- <vue-recaptcha
                        ref="recaptcha"
                        sitekey="6LeCy2sfAAAAALkd6r5eMtL3JvnrgITF4_XZLZEY"
                        :loadRecaptchaScript="true"
                        @verify="handleLogin()"
                      > -->
                      <v-btn
                        block
                        color="primary"
                        type="submit"
                        class="mt-6"
                        :disabled="invalid"
                        id="login_button_login_page"
                      >
                        {{ $t("Login") }}
                      </v-btn>
                      <!-- </vue-recaptcha> -->
                      <br />
                      <span>
                        <a @click="$router.push({ name: 'landing' })">
                          <v-icon>
                            {{ icons.mdiArrowLeft }}
                          </v-icon>
                          <span
                            :class="
                              $vuetify.theme.isDark
                                ? 'color-white-text'
                                : 'color-blue-text'
                            "
                          >
                            {{ $t("GoBack") }}
                          </span>
                        </a>
                      </span>
                    </v-form>
                  </ValidationObserver>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import AppBarThemeSwitcher from "@core/layouts/components/app-bar/AppBarThemeSwitcher.vue";

import { setVeeLocale } from "@/utils";
import { mdiEyeOutline, mdiEyeOffOutline, mdiArrowLeft } from "@mdi/js";
import { loadLanguageAsync } from "@/plugins/i18n";
import { mapActions, mapState } from "vuex";
import Modal from "./parts/ForgotModal.vue";
import ModalEN from "./parts/ForgotModalEN.vue";

import VueRecaptcha from "vue-recaptcha";

import { toLowerCase } from "@/utils";

export default {
  components: {
    VueRecaptcha,
    AppBarThemeSwitcher,
  },
  data() {
    return {
      isPasswordVisible: false,
      locales: [
        {
          title: "ES",
          locale: "es",
        },
        {
          title: "EN",
          locale: "en",
        },
      ],
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiArrowLeft,
      },
      userData: {
        // email: null,
        // password: null,
        email: "",
        // email: "",
        // email: "tesla@gmail.com",
        // password: "",
        password: "",
      },
    };
  },

  computed: {
    ...mapState("auth", ["user", "isLogged"]),
  },
  methods: {
    ...mapActions("auth", ["signIn", "me", "setLang"]),
    toLowerCase,

    async toggleModal() {
      const params = {
        fullscreen: false,
        width: this.$vuetify.breakpoint.smAndDown ? "80%" : "50%",
        transition: "dialog-top-transition",
      };

      if (this.$vuetify.lang.current === "es") {
        const dialogInstance = await this.$dialog.showAndWait(Modal, params);
      } else {
        const dialogInstance = await this.$dialog.showAndWait(ModalEN, params);
      }
    },

    handleLocale() {
      let prevLocal = this.$i18n.locale;
      let locale = "";
      switch (prevLocal) {
        case "es":
          locale = "en";
          break;
        case "en":
          locale = "es";
          break;
        default:
          break;
      }
      this.setLang(locale);
      setVeeLocale(locale);
      this.$vuetify.lang.current = locale;
      loadLanguageAsync(locale);
    },
    async handleLogin() {
      try {
        await this.signIn(this.userData);
        !!this.user && (await this.me());
        !!this.user && !this.$route.query.redirect
          ? this.$router.push({ name: "dashboards" })
          : this.$router.push({ path: this.$route.query.redirect });
      } catch (e) {
        console.log(e);
        e && e.message && e.message.includes("400")
          ? this.$dialog.notify.error(this.$t("Invalid credentials"))
          : this.$dialog.notify.error(e.message);
      }
    },
  },
  beforeMount() {
    this.isLogged && this.$router.push({ name: "dashboards" });
  },
};
</script>

<style lang="scss">
@import "@core/preset/preset/pages/auth.scss";
.side-panel {
  // background-image: url("../assets/login_side_panel.png");
  background: white;
  max-height: 100vh;
  &-image {
    object-fit: cover;
    // object-fit: contain;
  }
}
.mySwitcher:hover {
  cursor: pointer;
}
.puff-in-center {
  -webkit-animation: puff-in-center 0.7s cubic-bezier(0.47, 0, 0.745, 0.715)
    both;
  animation: puff-in-center 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) both;
}

.slide-in-bck-center {
  -webkit-animation: slide-in-bck-center 0.7s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-bck-center 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.fade-in {
  -webkit-animation: fade-in 1.5s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in 1.5s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.slide-in-left {
  -webkit-animation: slide-in-left 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-in-left 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.slide-in-down {
  -webkit-animation: slide-in-down 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-down 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.slide-in-right {
  -webkit-animation: slide-in-right 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-in-right 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

/////animations

/* ----------------------------------------------
 * Generated by Animista on 2022-3-30 21:34:31
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation puff-in-center
 * ----------------------------------------
 */
@-webkit-keyframes puff-in-center {
  0% {
    -webkit-transform: scale(2);
    transform: scale(2);
    -webkit-filter: blur(4px);
    filter: blur(4px);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
}
@keyframes puff-in-center {
  0% {
    -webkit-transform: scale(2);
    transform: scale(2);
    -webkit-filter: blur(4px);
    filter: blur(4px);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2022-3-30 21:36:6
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-bck-center
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-bck-center {
  0% {
    -webkit-transform: translateZ(600px);
    transform: translateZ(600px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}
@keyframes slide-in-bck-center {
  0% {
    -webkit-transform: translateZ(600px);
    transform: translateZ(600px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2022-3-30 21:37:33
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-in
 * ----------------------------------------
 */
@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

@-webkit-keyframes slide-in-down {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-in-down {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@-webkit-keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
</style>
